import { render, staticRenderFns } from "./dataTask.vue?vue&type=template&id=0315e1a6&scoped=true&"
import script from "./dataTask.vue?vue&type=script&lang=js&"
export * from "./dataTask.vue?vue&type=script&lang=js&"
import style0 from "./dataTask.vue?vue&type=style&index=0&id=0315e1a6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0315e1a6",
  null
  
)

export default component.exports