<!--
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2024-03-22 15:37:46
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2024-06-17 11:59:45
 * @FilePath: \cz-operation-web\src\views\systemManage\dataCenter.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="dataTask">
    <!-- <div class="statisticsBox">
      <div>
        <span>任务数</span>
        <span style="color: #1890ff">{{ statistics.taskNumber }}</span>
      </div>
      <div>
        <span>已成功运行（次）</span>
        <span style="color: #469225">{{ statistics.successNumber }}</span>
      </div>
      <div>
        <span>运行错误（次）</span>
        <span style="color: #fb2525">{{ statistics.errorNumber }}</span>
      </div>
      <div>
        <span>今日已运行</span>
        <span>{{ statistics.todayNumber }}</span>
      </div>
      <div>
        <span>今日已使用处理能力</span>
        <span>{{ statistics.todayUsed }}</span>
      </div>
    </div> -->
    <div class="card_title">数据调度任务</div>
    <a-form
      :form="form"
      layout="inline"
      style="margin-bottom: 20px; position: relative"
    >
      <a-form-item label="">
        <a-input-search
          placeholder="搜索租户id、租户名、id、任务名、描述"
          v-model="searchValue"
          @search="onSearch"
          :style="{ width: '300px', float: 'right' }"
        />
      </a-form-item>
    </a-form>

    <!-- :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }" -->
    <a-table
      :columns="columns"
      :data-source="taskList"
      :rowKey="(record) => record.id"
      :pagination="pagination"
      @change="tableChange"
    >
      <template slot="status" slot-scope="text, record">
        <span :class="`status-${text}`">{{ statusText(text) }}</span>
        <span v-if="text == '1' && record.errorMessage"
          >（
          <ellipsis :length="30" tooltip>{{ record.errorMessage }}）</ellipsis>
        </span>
      </template>
      <!-- <template slot="enabled" slot-scope="text, record">
        <span
          ><a-switch :checked="!!text" @change="fieldUpdate(record)"
        /></span>
      </template> -->
      <template slot="creator" slot-scope="text, record">
        <div class="creator-container">
          <!-- <c-avatar class="creator-img" alt="" :src="record.creatorAvatar | imgFormat" /> -->
          <u-avatar
            class="creator-img"
            :picUrl="record.creatorAvatar"
            :name="record.creatorName"
            width="30px"
            height="30px"
            style="margin-right: 10px"
          ></u-avatar>
          <span class="creator-name">
            <ellipsis :length="10" tooltip>{{ record.creatorName }}</ellipsis>
          </span>
        </div>
      </template>
      <div slot="operation" slot-scope="text, record">
        <!-- <a v-if="record.enabled" @click="stop(record)">停止</a>
        <a-divider type="vertical" v-if="record.enabled" />
        <a @click="del(record)">删除</a>
        <a-divider type="vertical" /> -->
        <a @click="log(record)">查看运行日志</a>
      </div>
    </a-table>
  </div>
</template>

<script>
// import infiniteScroll from 'vue-infinite-scroll'
// import { RecycleScroller } from 'vue-virtual-scroller'
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
const columns = [
  {
    title: '租户id',
    dataIndex: 'tenantId',
    key: 'tenantId',
    scopedSlots: { customRender: 'tenantId' },
    align: 'left',
  },
  {
    title: '租户名',
    dataIndex: 'tenant',
    key: 'tenant',
    scopedSlots: { customRender: 'tenant' },
    align: 'left',
  },
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    align: 'left',
  },
  {
    title: '任务名',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
    align: 'left',
  },
  // {
  //   title: '开关',
  //   dataIndex: 'enabled',
  //   key: 'enabled',
  //   scopedSlots: { customRender: 'enabled' },
  //   align: 'left',
  // },

  // {
  //   title: '执行方式',
  //   dataIndex: 'startWay',
  //   key: 'startWay',
  //   scopedSlots: { customRender: 'startWay' },
  //   align: 'left',
  // },
  {
    title: '运行次数',
    dataIndex: 'executions',
    key: 'executions',
    scopedSlots: { customRender: 'executions' },
    align: 'left',
    width: '80px',
  },
  {
    title: '累计使用处理能力',
    dataIndex: 'flow',
    key: 'flow',
    scopedSlots: { customRender: 'flow' },
    align: 'left',
    width: '150px',
  },
  {
    title: '最近运行结束时间',
    dataIndex: 'lastEndTime',
    key: 'lastEndTime',
    scopedSlots: { customRender: 'lastEndTime' },
    align: 'left',
  },
  {
    title: '最近一次运行状态',
    dataIndex: 'status',
    key: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'left',
  },
  // {
  //   title: 'CRON',
  //   dataIndex: 'cron',
  //   key: 'cron',
  //   scopedSlots: { customRender: 'cron' },
  //   align: 'left',
  // },
  {
    title: '创建人',
    dataIndex: 'creator',
    key: 'creator',
    scopedSlots: { customRender: 'creator' },
    align: 'left',
    width: '180px',
  },

  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'left',
    width: '150px',
    fixed: 'right',
  },
]
export default {
  name: 'DataTask',

  data() {
    return {
      form: this.$form.createForm(this),
      columns,
      pagination: {
        current: 1, // 默认当前页数
        pageSize: 10, // 默认当前页显示数据的大小
        total: 20,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        pageSizeOptions: ['10', '15', '20'],
      },
      taskList: [] /* 任务列表 */,
      selectedRowKeys: [],
      options: [],
      loading: false,
      page: 0,
      size: 10,
      busy: false,
      selectedItem: null,
      value: '',
      searchValue: '' /* 搜索关键词 */,
      statusLists: [
        { label: '未运行', value: '0' },
        { label: '运行正常', value: '2' },
        { label: '运行错误', value: '1' },
      ],
      statistics: {
        taskNumber: 1,
        successNumber: 10,
        errorNumber: 9,
        todayNumber: 8,
        todayUsed: '200.00MB',
      },
    }
  },
  computed: {
    statusText() {
      return function (value) {
        const target = this.statusLists.find((item) => item.value === value)
        return target ? target.label : '-'
      }
    },
  },

  created() {
    this.init()
  },

  methods: {
    // 开关变更
    async fieldUpdate(record) {
      let params = {
        ...record,
        enabled: record.enabled ? 0 : 1,
      }
      // this.loading = true
      // const { code, data } = await editDataDispatch(params).catch((err) => {
      //   console.error(err)
      //   this.loading = false
      // })
      // if (code == 0) {
      //   // this.$message.success(`编辑成功`)
      //   this.loadLists()
      // }
    },
    /* 表格单选框变化 */
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      // console.log(this.selectedRowKeys)
    },
    /* 表格变化（分页） */
    tableChange(pagination) {
      let { current, pageSize } = pagination
      // this.pagination.current = current
      // this.pagination.pageSize = pageSize
      let params = {
        limit: pageSize,
        page: current,
      }
      let values = this.form.getFieldsValue()
      if (values.tid) {
        params.tenantId = values.tid
      }
      this.getJobListData(params).then(() => {
        this.pagination.current = current
        this.pagination.pageSize = pageSize
      })
    },

    onSearch(value) {
      this.searchValue = value
      this.getJobListData()
    },

    async init() {
      this.page = 1
      let res = await this.$api._getTenantInfoList({ page: 1, size: 10 })
      this.options = res.data.content

      this.getJobListData({ page: 1, limit: 10 })
    },

    /* 选择框-滚动加载新的租户数据 */
    // async handleInfiniteOnLoad(e) {
    //   const bottomOffset =
    //     e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
    //   if (bottomOffset <= 1) {
    //     // console.log('jjjjjjjj')

    //     this.loading = true

    //     let params = {
    //       page: this.page + 1,
    //       size: this.size,
    //     }
    //     if (this.value) Object.assign(params, { key: this.value })
    //     const res = await this.$api._getTenantInfoList(params)
    //     const nextPageData = res.data.content
    //     this.options = this.options.concat(nextPageData)
    //     this.page++
    //     this.loading = false
    //   }
    // },

    /* 选择框-搜索租户 */
    // async handleChange(value) {
    //   console.log(value)
    //   this.value = value
    //   this.page = 1
    //   let res
    //   if (this.value) {
    //     res = await this.$api._getTenantInfoList({
    //       page: 1,
    //       size: 10,
    //       key: this.value,
    //     })
    //   } else {
    //     res = await this.$api._getTenantInfoList({
    //       page: 1,
    //       size: 10,
    //     })
    //   }
    //   const nextPageData = res.data.content
    //   this.options = nextPageData
    // },
    /* 分页获取租户列表数据 */

    // async getTenantList(params) {
    //   let res = await this.$api._getTenantInfoList(params)
    // },

    /* 获取任务列表 */
    async getJobListData(params) {
      if (this.searchValue) {
        params = { ...params, key: this.searchValue }
      }
      let res = await this.$api.getJobList(params)
      // console.log(res, '任务列表')

      if (res.code == 0) {
        this.taskList = res.data.records
        this.pagination.total = Number(res.data.total)
        Promise.resolve()
      }
    },

    /* 删除 */
    // del(record) {
    //   let _this = this
    //   console.log('被输出值{ record }的输出结果是：', record)
    //   this.$confirm({
    //     title: '确认删除该任务吗？',
    //     content: '删除后无法恢复，请慎重操作',
    //     onOk() {
    //       _this.$api.deleteJob(record.id).then((res) => {
    //         if (res.code === 0) {
    //           _this.$message.success('删除成功')
    //           _this.init()
    //         }
    //       })
    //     },
    //     onCancel() {},
    //   })
    // },
    /* 停止 */
    // stop(record) {
    //   let _this = this
    //   console.log('被输出值{ record }的输出结果是：', record)
    //   let { enabled, ...data } = record
    //   let _data = {
    //     ...data,
    //     enabled: 0,
    //   }
    //   this.$confirm({
    //     title: '确认停止该任务吗？',
    //     // content: '删除后无法恢复，请慎重操作',
    //     onOk() {
    //       _this.$api.stopJob(_data).then((res) => {
    //         if (res.code === 0) {
    //           _this.$message.success('修改成功')
    //           _this.init()
    //         }
    //       })
    //     },
    //     onCancel() {},
    //   })
    // },
    /* 日志 */
    log(record) {
      // this.$api.jobLog(record.id).then((res) => {
      //   console.log('被输出值{ 日志 }的输出结果是：', res)
      // })
      this.$router.push({
        name: 'TaskLog',
        query: {
          id: record.id,
        },
      })
    },
    /* 筛选条件确认 */
    // handleOk() {
    //   this.form.validateFields((err, values) => {
    //     if (!err) {
    //       console.log('被输出值{ values }的输出结果是：', values)
    //       /* 根据条件赛选任务列表 */
    //       let params = {
    //         limit: this.pagination.pageSize,
    //         page: 1,
    //       }
    //       if (values.tid) {
    //         params.tenantId = values.tid
    //       }
    //       if (values.startWay) {
    //         params.startWay = values.startWay
    //       }
    //       this.getJobListData(params).then(() => {
    //         this.pagination.current = 1
    //       })
    //     }
    //   })
    // },
  },
}
</script>

<style lang="less" scoped>
.dataTask {
  background-color: #fff;
  padding: 15px;
}
.card_title {
  font-family: 'PingFangSC Medium';
  // font-weight: 500;
  font-size: 15px;
  text-align: left;
  color: #292929;
  font-weight: bold;
  margin-bottom: 10px;
}

/deep/ .ant-table-align-left {
  height: 44px !important;
  padding: 0;
  padding-left: 16px;
  font-family: 'PingFang SC Bold';
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #333;
}

/deep/ .ant-table-tbody > tr > td {
  padding: 10px 16px 10px 16px;
}

.status-2 {
  color: #52c41a;
}
.status-1 {
  color: #f5222d;
}

// .batchOperation {
//   position: fixed;
//   bottom: 40px;
//   width: 926px;
//   height: 62px;
//   padding: 0 28px;
//   border-radius: 6px;
//   background: #fff;
//   box-shadow: 0 5px 15px rgba(176, 183, 195, 0.24);
//   left: 50%;
//   transform: translateX(-50%);
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

.statisticsBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  div {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #f2f2f2;
    min-width: 200px;
    & > span:nth-of-type(1) {
      font-family: 'PingFangSC';
      font-weight: 400;
      font-size: 13px;
      text-align: left;
      color: #999;
    }
    & > span:nth-of-type(2) {
      font-family: 'PingFangSC';
      font-weight: 600;
      font-size: 18px;
      text-align: left;
    }
  }
}
</style>
